import React from 'react'

import { Helmet } from 'react-helmet'

import Branding from '../components/branding'
import AboutHuge from '../components/about-huge'
import BlockLeft from '../components/block-left'
import BlockRight from '../components/block-right'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>WIKKI Inteligência</title>
        <meta property="og:title" content="WIKKI Inteligência" />
      </Helmet>
      <section className="home-hero">
        <div className="home-head">
          <div className="home-main">
            <div className="home-content">
              <Branding
                imageSrc="/assets/logo1.svg"
                companyName="Wikki Inteligência"
                rootClassName="branding-root-class-name1"
              ></Branding>
              <div className="home-hero-section">
                <div className="home-header">
                  <h1 className="home-heading">
                    <span>
                      Soluções
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="home-text01">
                      inovadoras
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      aplicando Inteligência Artificial à problemas reais da
                      indústria
                    </span>
                    <br></br>
                  </h1>
                  <p className="home-caption">
                    <span>
                      A Wikki conecta especialistas e universidades de
                      excelência à realidade empresarial para fornecer soluções
                      tecnológicas personalizadas. Da automação de tarefas
                      rotineiras à otimização de processos complexos, estamos
                      aqui para simplificar e potencializar suas operações.
                    </span>
                    <br className="Content"></br>
                  </p>
                </div>
                <div className="home-buttons">
                  <a
                    href="mailto:contato@wikkiinteligencia.com.br?subject="
                    className="home-link button"
                  >
                    <span className="home-text06">Fale Conosco</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="home-images">
              <img src="/assets/background1.svg" className="home-image" />
              <img src="/assets/background2.svg" className="home-image01" />
            </div>
          </div>
        </div>
      </section>
      <div className="home-partners-container">
        <ul className="home-partners list">
          <li className="home-li list-item">
            <a
              href="http://www.gesar.uerj.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/gesar.svg"
                className="home-image02"
              />
            </a>
          </li>
          <li className="home-li01 list-item">
            <a
              href="https://pgmec.uff.br/laboratorio-de-opto-mecanica-lom/ "
              target="_blank"
              rel="noreferrer noopener"
            >
              <img alt="image" src="/assets/lom.svg" className="home-image03" />
            </a>
          </li>
          <li className="home-li02 list-item">
            <a
              href="https://coppe.ufrj.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/coppe.svg"
                className="home-image04"
              />
            </a>
          </li>
          <li className="home-li03 list-item">
            <a
              href="https://www.recogna.tech/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/recogna.svg"
                className="home-image05"
              />
            </a>
          </li>
          <li className="home-li04 list-item">
            <a
              href="http://www.eq.ufrj.br/labcfd/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/labcfd.svg"
                className="home-image06"
              />
            </a>
          </li>
          <li className="home-li05 list-item">
            <a
              href="https://latermo.uff.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/latermo.svg"
                className="home-image07"
              />
            </a>
          </li>
          <li className="home-li06 list-item">
            <img
              alt="image"
              src="/assets/optgis.svg"
              className="home-image08"
            />
          </li>
          <li className="home-li07 list-item">
            <a
              href="https://cemeai.icmc.usp.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/cemeai.svg"
                className="home-image09"
              />
            </a>
          </li>
          <li className="home-li08 list-item">
            <a
              href="http://www.gedif.uff.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/gedif.svg"
                className="home-image10"
              />
            </a>
          </li>
          <li className="home-li09 list-item">
            <a
              href="https://tecnologiaindustrial.ufes.br/laboratorios"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/lavagante.svg"
                className="home-image11"
              />
            </a>
          </li>
          <li className="home-li10 list-item">
            <a
              href="http://www.lamcso.coppe.ufrj.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/lamcso.svg"
                className="home-image12"
              />
            </a>
          </li>
          <li className="home-li11 list-item">
            <a
              href="https://cernn.com.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/cernn.svg"
                className="home-image13"
              />
            </a>
          </li>
        </ul>
        <ul className="home-partners1 list">
          <li className="home-li12 list-item">
            <a
              href="http://www.gesar.uerj.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/gesar.svg"
                className="home-image14"
              />
            </a>
          </li>
          <li className="home-li13 list-item">
            <a
              href="https://pgmec.uff.br/laboratorio-de-opto-mecanica-lom/ "
              target="_blank"
              rel="noreferrer noopener"
            >
              <img alt="image" src="/assets/lom.svg" className="home-image15" />
            </a>
          </li>
          <li className="home-li14 list-item">
            <a
              href="https://coppe.ufrj.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/coppe.svg"
                className="home-image16"
              />
            </a>
          </li>
          <li className="home-li15 list-item">
            <a
              href="https://www.recogna.tech/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/recogna.svg"
                className="home-image17"
              />
            </a>
          </li>
          <li className="home-li16 list-item">
            <a
              href="http://www.eq.ufrj.br/labcfd/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/labcfd.svg"
                className="home-image18"
              />
            </a>
          </li>
          <li className="home-li17 list-item">
            <a
              href="https://latermo.uff.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/latermo.svg"
                className="home-image19"
              />
            </a>
          </li>
          <li className="home-li18 list-item">
            <img
              alt="image"
              src="/assets/optgis.svg"
              className="home-image20"
            />
          </li>
          <li className="home-li19 list-item">
            <a
              href="https://cemeai.icmc.usp.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/cemeai.svg"
                className="home-image21"
              />
            </a>
          </li>
          <li className="home-li20 list-item">
            <a
              href="http://www.gedif.uff.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/gedif.svg"
                className="home-image22"
              />
            </a>
          </li>
          <li className="home-li21 list-item">
            <a
              href="https://tecnologiaindustrial.ufes.br/laboratorios"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/lavagante.svg"
                className="home-image23"
              />
            </a>
          </li>
          <li className="home-li22 list-item">
            <a
              href="http://www.lamcso.coppe.ufrj.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/lamcso.svg"
                className="home-image24"
              />
            </a>
          </li>
          <li className="home-li23 list-item">
            <a
              href="https://cernn.com.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/cernn.svg"
                className="home-image25"
              />
            </a>
          </li>
        </ul>
      </div>
      <section className="home-quote-one">
        <div className="home-quote-content">
          <h2 className="home-quote">
            <span className="quote home-text07">
              “Temos a
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text08">agilidade</span>
            <span className="quote home-text09">
              {' '}
              de uma startup e a
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text10">expertise</span>
            <span className="quote home-text11">
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="quote home-text12">de uma universidade.”</span>
          </h2>
        </div>
        <div className="home-quote-content1">
          <h2 className="home-quote1">
            A WIKKI combina uma cultura empreendedora caracterizada por uma
            abordagem criativa e orientada à resultados.
          </h2>
        </div>
      </section>
      <div className="home-about">
        <div className="home-container01">
          <div className="home-container02">
            <h1 className="home-text13 quote">
              <span>Por que a Wikki?</span>
              <br></br>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </h1>
            <span className="home-text17">
              A Wikki se diferencia através de soluções personalizadas devido
              sua expertise em desenvolvimento de códigos Open Source, atendendo
              a diferentes setores, que vão da indústria do óleo e gás à saúde e
              à comunicação.
            </span>
          </div>
          <div className="home-container03">
            <AboutHuge
              text="Equipe Altamente Qualificada"
              text1="Parcerias com renomadas universidades e institutos de pesquisa, além de uma equipe multidisciplinar altamente qualificada."
              imageSrc="/assets/belt.svg"
              rootClassName="about-huge-root-class-name2"
            ></AboutHuge>
            <AboutHuge
              text="Soluções Personalizadas"
              text1="Desenvolvidas de acordo com a necessidade do cliente e do segmento de mercado que está inserido."
              imageSrc="/assets/linux.svg"
              rootClassName="about-huge-root-class-name"
            ></AboutHuge>
            <AboutHuge
              text="Agilidade"
              text1='O nome Wikki é inspirado na expressão havaiana "wiki-wiki" que significa "rápido" ou "veloz". Reconhecida pela construção de softwares de código aberto, a Wikki destaca-se por sua rapidez e excelência.'
              imageSrc="/assets/mask.svg"
              rootClassName="about-huge-root-class-name1"
            ></AboutHuge>
            <AboutHuge
              text="Colaboração e Inovação Aberta"
              text1="A Wikki mostra que é possível desenvolver soluções tecnológicas de alta qualidade através da inovação aberta, integrando renomadas universidades, starturps, institutos de pesquisa e indústrias no desafio."
              imageSrc="/assets/inovacao.svg"
            ></AboutHuge>
          </div>
        </div>
      </div>
      <div className="home-steps">
        <h1 className="home-text18 title">Como sua Solução Nasce</h1>
        <span className="home-text19 Content">
          <span>
            A Wikki adota a Inovação Aberta como modelo de gestão em todas as
            etapas do seu projeto, gerando um ecossistema de inovação para criar
            soluções de alto impacto.
          </span>
          <br></br>
        </span>
        <div className="home-container04">
          <div className="home-step">
            <div className="home-container05">
              <div className="home-line"></div>
              <div className="home-container06">
                <svg viewBox="0 0 1024 1024" className="home-icon">
                  <path d="M992.262 871.396l-242.552-206.294c-25.074-22.566-51.89-32.926-73.552-31.926 57.256-67.068 91.842-154.078 91.842-249.176 0-212.078-171.922-384-384-384-212.076 0-384 171.922-384 384s171.922 384 384 384c95.098 0 182.108-34.586 249.176-91.844-1 21.662 9.36 48.478 31.926 73.552l206.294 242.552c35.322 39.246 93.022 42.554 128.22 7.356s31.892-92.898-7.354-128.22zM384 640c-141.384 0-256-114.616-256-256s114.616-256 256-256 256 114.616 256 256-114.614 256-256 256z"></path>
                </svg>
              </div>
              <div className="home-line1"></div>
            </div>
            <div className="home-container07">
              <h1 className="home-text22 titlecard">
                Entendimento e Quantificação do Problema
              </h1>
              <span className="home-text23">
                O quanto podemos melhorar o que já existe e o que podemos fazer
                de novo? Para responder essa pergunta, precisamos entender a
                estrutura do problema e realizar uma análise dos dados
                disponíveis.
              </span>
            </div>
          </div>
          <div className="home-step1">
            <div className="home-container08">
              <div className="home-line2"></div>
              <div className="home-container09">
                <svg
                  viewBox="0 0 1025.1702857142857 1024"
                  className="home-icon2"
                >
                  <path d="M1008 6.286c12 8.571 17.714 22.286 15.429 36.571l-146.286 877.714c-1.714 10.857-8.571 20-18.286 25.714-5.143 2.857-11.429 4.571-17.714 4.571-4.571 0-9.143-1.143-13.714-2.857l-258.857-105.714-138.286 168.571c-6.857 8.571-17.143 13.143-28 13.143-4 0-8.571-0.571-12.571-2.286-14.286-5.143-24-18.857-24-34.286v-199.429l493.714-605.143-610.857 528.571-225.714-92.571c-13.143-5.143-21.714-17.143-22.857-31.429-0.571-13.714 6.286-26.857 18.286-33.714l950.857-548.571c5.714-3.429 12-5.143 18.286-5.143 7.429 0 14.857 2.286 20.571 6.286z"></path>
                </svg>
              </div>
              <div className="home-line3"></div>
            </div>
            <div className="home-container10">
              <h1 className="home-text24 titlecard">Desenvolvimento do MVP</h1>
              <span className="home-text25">
                A solução desenvolvida resolve o problema? Sua implantação é
                factível à realidade da empresa? Com o desenvolvimento de um
                produto mínimo viável é possível avaliar se a solução idealizada
                de fato atende o problema proposto.
              </span>
            </div>
          </div>
          <div className="home-step2">
            <div className="home-container11">
              <div className="home-line4"></div>
              <div className="home-container12">
                <svg viewBox="0 0 1024 1024" className="home-icon4">
                  <path d="M576 736l96 96 320-320-320-320-96 96 224 224z"></path>
                  <path d="M448 288l-96-96-320 320 320 320 96-96-224-224z"></path>
                </svg>
              </div>
              <div className="home-line5"></div>
            </div>
            <div className="home-container13">
              <h1 className="home-text26 titlecard">Testes e Adaptações</h1>
              <span className="home-text27">
                Aplicação do MVP em áreas estratégicas da empresa. Com isso,
                coletamos feedbacks práticos de como potencializar a solução
                desenvolvida para alavancar os resultados obtidos.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
          </div>
          <div className="home-step3">
            <div className="home-container14">
              <div className="home-line6"></div>
              <div className="home-container15">
                <svg
                  viewBox="0 0 967.4605714285714 1024"
                  className="home-icon7"
                >
                  <path d="M822.857 256c0-30.286-24.571-54.857-54.857-54.857s-54.857 24.571-54.857 54.857 24.571 54.857 54.857 54.857 54.857-24.571 54.857-54.857zM950.857 91.429c0 189.714-52.571 316-188 452-33.143 32.571-70.857 66.286-111.429 100.571l-11.429 216.571c-0.571 5.714-4 11.429-9.143 14.857l-219.429 128c-2.857 1.714-5.714 2.286-9.143 2.286-4.571 0-9.143-1.714-13.143-5.143l-36.571-36.571c-4.571-5.143-6.286-12-4.571-18.286l48.571-157.714-160.571-160.571-157.714 48.571c-1.714 0.571-3.429 0.571-5.143 0.571-4.571 0-9.714-1.714-13.143-5.143l-36.571-36.571c-5.714-6.286-6.857-15.429-2.857-22.286l128-219.429c3.429-5.143 9.143-8.571 14.857-9.143l216.571-11.429c34.286-40.571 68-78.286 100.571-111.429 142.857-142.286 252-188 450.857-188 10.286 0 19.429 8 19.429 18.286z"></path>
                </svg>
              </div>
              <div className="home-line7"></div>
            </div>
            <div className="home-container16">
              <h1 className="home-text28 titlecard">Escalando o Produto</h1>
              <span className="home-text29">
                Realizamos os ajustes necessários e refinamos o produto. Então,
                é feita a implantação da solução final em toda a empresa.
              </span>
            </div>
          </div>
        </div>
        <div className="home-buttons1">
          <h2 className="home-quote2 Content">
            Quer saber mais sobre como podemos te ajudar?
          </h2>
          <a
            href="mailto:contato@wikkiinteligencia.com.br?subject="
            className="home-link23 button"
          >
            <span className="home-text30">Nos envie um email!</span>
          </a>
        </div>
      </div>
      <div className="home-cases">
        <div className="home-container17">
          <div className="home-title">
            <h1 className="home-text31">
              <span>Alguns dos Nossos Cases</span>
              <br></br>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </h1>
          </div>
          <BlockLeft
            text1="O principal objetivo dessa IA é aprimorar a análise de imagens de poços de petróleo, através de Visão Computacional e Aprendizado de Máquina."
            text2="Modelos eficientes para identificação e classificação de texturas e estruturas geológicas trazem avanços significativos para a exploração de petróleo e gás."
            tittle="IA para Padrões Geológicos"
            verticalArt="/assets/geo.svg"
            rootClassName="block-left-root-class-name"
          ></BlockLeft>
          <BlockRight
            text1="Utilizando metodologias avançadas, avaliamos a performance e confiabilidade em barreiras de segurança na indústria do Óleo e Gás."
            text2="Através de modelos estatísticos e Machine Learning é possível estimar as taxas de falha de válvulas para implementação de estratégias de manutenção preditiva e preventiva, aumentado a segurança e reduzindo riscos."
            tittle="IA de Manutenção Preditiva"
            verticalArt="/assets/manutencao.svg"
            rootClassName="block-right-root-class-name1"
          ></BlockRight>
          <BlockLeft
            text1="Esse software é capaz de identificar e classificar nanofósseis em amostras geológicas, a partir de modelos de Aprendizado de Máquina e Reconhecimento de Imagem altamente precisos."
            text2="Utilizando Redes Neurais, agiliza-se análises cruciais na indústria de Óleo e Gás."
            tittle="IA para Classificação de Nanofósseis"
            verticalArt="/assets/nano.svg"
            rootClassName="block-left-root-class-name1"
          ></BlockLeft>
        </div>
      </div>
      <div className="home-partners-container1">
        <ul className="home-partners2 list">
          <li className="home-list-item list-item">
            <img
              alt="image"
              src="/assets/apache.svg"
              className="home-image26"
            />
          </li>
          <li className="home-li24 list-item">
            <a
              href="https://blazor.radzen.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/blazor.svg"
                className="home-image27"
              />
            </a>
          </li>
          <li className="home-li25 list-item">
            <a
              href="https://www.djangoproject.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/django.svg"
                className="home-image28"
              />
            </a>
          </li>
          <li className="home-li26 list-item">
            <a
              href="https://www.erlang.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/erlang.svg"
                className="home-image29"
              />
            </a>
          </li>
          <li className="home-li27 list-item">
            <a
              href="https://jestjs.io/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/jest.svg"
                className="home-image30"
              />
            </a>
          </li>
          <li className="home-li28 list-item">
            <a
              href="https://laravel.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/laravel.svg"
                className="home-image31"
              />
            </a>
          </li>
          <li className="home-li29 list-item">
            <a
              href="https://www.latex-project.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/latex.svg"
                className="home-image32"
              />
            </a>
          </li>
          <li className="home-li30 list-item">
            <a
              href="https://www.nginx.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/nginx.svg"
                className="home-image33"
              />
            </a>
          </li>
          <li className="home-li31 list-item">
            <a
              href="https://nodejs.org/en"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/node.svg"
                className="home-image34"
              />
            </a>
          </li>
          <li className="home-li32 list-item">
            <a
              href="https://openjdk.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/openjdk.svg"
                className="home-image35"
              />
            </a>
          </li>
          <li className="home-li33 list-item">
            <a
              href="https://docs.pytest.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/pytest.svg"
                className="home-image36"
              />
            </a>
          </li>
          <li className="home-li34 list-item">
            <a
              href="https://www.qt.io/download-open-source"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img alt="image" src="/assets/qt.svg" className="home-image37" />
            </a>
          </li>
          <li className="home-li35 list-item">
            <a
              href="https://redis.io/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/redis.svg"
                className="home-image38"
              />
            </a>
          </li>
          <li className="home-li36 list-item">
            <a
              href="https://www.ruby-lang.org/en/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/ruby.svg"
                className="home-image39"
              />
            </a>
          </li>
          <li className="home-li37 list-item">
            <a
              href="https://sylabs.io/singularity/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/singularity.svg"
                className="home-image40"
              />
            </a>
          </li>
          <li className="home-li38 list-item">
            <a
              href="https://www.virtualbox.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/virtualbox.svg"
                className="home-image41"
              />
            </a>
          </li>
          <li className="home-li39 list-item">
            <a
              href="https://www.wildfly.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/wildfly.svg"
                className="home-image42"
              />
            </a>
          </li>
          <li className="home-li40 list-item">
            <a
              href="https://solidproject.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/solid.svg"
                className="home-image43"
              />
            </a>
          </li>
          <li className="home-li41 list-item">
            <a
              href="https://ubuntu.com/download/desktop"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/ubuntu.svg"
                className="home-image44"
              />
            </a>
          </li>
          <li className="home-li42 list-item">
            <a
              href="https://vitejs.dev/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/vitejs.svg"
                className="home-image45"
              />
            </a>
          </li>
          <li className="home-li43 list-item">
            <a
              href="https://www.sonarsource.com/open-source-editions/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/sonarsource.svg"
                className="home-image46"
              />
            </a>
          </li>
        </ul>
        <ul className="home-partners3 list">
          <li className="home-list-item1 list-item">
            <img
              alt="image"
              src="/assets/apache.svg"
              className="home-image47"
            />
          </li>
          <li className="home-li44 list-item">
            <a
              href="https://blazor.radzen.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/blazor.svg"
                className="home-image48"
              />
            </a>
          </li>
          <li className="home-li45 list-item">
            <a
              href="https://www.djangoproject.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/django.svg"
                className="home-image49"
              />
            </a>
          </li>
          <li className="home-li46 list-item">
            <a
              href="https://www.erlang.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/erlang.svg"
                className="home-image50"
              />
            </a>
          </li>
          <li className="home-li47 list-item">
            <a
              href="https://jestjs.io/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/jest.svg"
                className="home-image51"
              />
            </a>
          </li>
          <li className="home-li48 list-item">
            <a
              href="https://laravel.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/laravel.svg"
                className="home-image52"
              />
            </a>
          </li>
          <li className="home-li49 list-item">
            <a
              href="https://www.latex-project.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/latex.svg"
                className="home-image53"
              />
            </a>
          </li>
          <li className="home-li50 list-item">
            <a
              href="https://www.nginx.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/nginx.svg"
                className="home-image54"
              />
            </a>
          </li>
          <li className="home-li51 list-item">
            <a
              href="https://nodejs.org/en"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/node.svg"
                className="home-image55"
              />
            </a>
          </li>
          <li className="home-li52 list-item">
            <a
              href="https://openjdk.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/openjdk.svg"
                className="home-image56"
              />
            </a>
          </li>
          <li className="home-li53 list-item">
            <a
              href="https://docs.pytest.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/pytest.svg"
                className="home-image57"
              />
            </a>
          </li>
          <li className="home-li54 list-item">
            <a
              href="https://www.qt.io/download-open-source"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img alt="image" src="/assets/qt.svg" className="home-image58" />
            </a>
          </li>
          <li className="home-li55 list-item">
            <a
              href="https://redis.io/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/redis.svg"
                className="home-image59"
              />
            </a>
          </li>
          <li className="home-li56 list-item">
            <a
              href="https://www.ruby-lang.org/en/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/ruby.svg"
                className="home-image60"
              />
            </a>
          </li>
          <li className="home-li57 list-item">
            <a
              href="https://sylabs.io/singularity/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/singularity.svg"
                className="home-image61"
              />
            </a>
          </li>
          <li className="home-li58 list-item">
            <a
              href="https://www.virtualbox.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/virtualbox.svg"
                className="home-image62"
              />
            </a>
          </li>
          <li className="home-li59 list-item">
            <a
              href="https://www.wildfly.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/wildfly.svg"
                className="home-image63"
              />
            </a>
          </li>
          <li className="home-li60 list-item">
            <a
              href="https://solidproject.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/solid.svg"
                className="home-image64"
              />
            </a>
          </li>
          <li className="home-li61 list-item">
            <a
              href="https://ubuntu.com/download/desktop"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/ubuntu.svg"
                className="home-image65"
              />
            </a>
          </li>
          <li className="home-li62 list-item">
            <a
              href="https://vitejs.dev/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/vitejs.svg"
                className="home-image66"
              />
            </a>
          </li>
          <li className="home-li63 list-item">
            <a
              href="https://www.sonarsource.com/open-source-editions/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="image"
                src="/assets/sonarsource.svg"
                className="home-image67"
              />
            </a>
          </li>
        </ul>
      </div>
      <div className="home-call-to-action">
        <span className="home-text35">
          Está enfrentando um desafio tecnológico?
        </span>
        <h1 className="home-text36">
          <span className="title">
            Desenvolva sua
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="home-text38">
            solução
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="title">agora mesmo com a Wikki!</span>
        </h1>
        <div className="home-buttons2">
          <a
            href="mailto:contato@wikkiinteligencia.com.br?subject="
            className="home-link64 button"
          >
            <span className="home-text40">Fale Conosco</span>
          </a>
        </div>
      </div>
      <section className="home-quote-one1">
        <div className="home-quote-content2">
          <h2 className="home-quote3">
            <span>
              &quot;O diferencial da Wikki está em diferenciar a
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text42">
              estrutura
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              do que chamo de
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text44">
              conteudo
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>do desafio.</span>
            <br></br>
            <span>
              Ao fazer essa diferenciação otimizamos a
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text48">estrutura dos desafios</span>
            <span> de nossos clientes sem comprometer o seu </span>
            <span className="home-text50">core business</span>
            <span>.”</span>
          </h2>
          <div className="home-quoted">
            <img
              alt="image"
              src="/assets/rodrigo-200h.jpg"
              className="home-image68"
            />
            <p className="home-caption1">
              Rodrigo Dias, Sócio da Wikki Inteligência
            </p>
          </div>
        </div>
      </section>
      <footer className="home-footer">
        <div className="home-top">
          <nav className="home-navigation-links">
            <a
              href="mailto:contato@wikkiinteligencia.com.br?subject="
              className="home-link65 navLink"
            >
              Contato
            </a>
          </nav>
        </div>
        <img
          alt="image"
          src="/assets/logo_branca.svg"
          className="home-image69"
        />
      </footer>
    </div>
  )
}

export default Home

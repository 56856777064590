import React from 'react'

import PropTypes from 'prop-types'

import './block-left.css'

const BlockLeft = (props) => {
  return (
    <div className={`block-left-container ${props.rootClassName} `}>
      <div className="block-left-image">
        <img
          alt={props.verticalAlt}
          src={props.verticalArt}
          className="block-left-image1"
        />
      </div>
      <div className="block-left-text">
        <h2 className="block-left-tittle">{props.tittle}</h2>
        <div className="block-left-content">
          <span className="block-left-text1">{props.text1}</span>
          <span className="block-left-text2">{props.text2}</span>
        </div>
      </div>
    </div>
  )
}

BlockLeft.defaultProps = {
  verticalArt: '/assets/test.svg',
  text1:
    'A Wikki conecta especialistas e universidades de excelência para enfrentar diversos desafios e fornecer soluções tecnológicas personalizadas, combinando expertise acadêmica com a agilidade de uma startup.',
  rootClassName: '',
  verticalAlt: 'image',
  text2:
    'A Wikki conecta especialistas e universidades de excelência para enfrentar diversos desafios e fornecer soluções tecnológicas personalizadas, combinando expertise acadêmica com a agilidade de uma startup.',
  tittle: 'Soluções Através de Tecnologias Open Source',
}

BlockLeft.propTypes = {
  verticalArt: PropTypes.string,
  text1: PropTypes.string,
  rootClassName: PropTypes.string,
  verticalAlt: PropTypes.string,
  text2: PropTypes.string,
  tittle: PropTypes.string,
}

export default BlockLeft

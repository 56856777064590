import React from 'react'

import PropTypes from 'prop-types'

import './block-right.css'

const BlockRight = (props) => {
  return (
    <div className={`block-right-container ${props.rootClassName} `}>
      <div className="block-right-text">
        <h2 className="block-right-tittle">{props.tittle}</h2>
        <div className="block-right-content">
          <span className="block-right-text1">{props.text1}</span>
          <span className="block-right-text2">{props.text2}</span>
        </div>
      </div>
      <div className="block-right-image">
        <img
          alt={props.verticalAlt}
          src={props.verticalArt}
          className="block-right-image1"
        />
      </div>
    </div>
  )
}

BlockRight.defaultProps = {
  verticalAlt: 'image',
  text1:
    'A Wikki conecta especialistas e universidades de excelência para enfrentar diversos desafios e fornecer soluções tecnológicas personalizadas, combinando expertise acadêmica com a agilidade de uma startup.',
  text2:
    'A Wikki conecta especialistas e universidades de excelência para enfrentar diversos desafios e fornecer soluções tecnológicas personalizadas, combinando expertise acadêmica com a agilidade de uma startup.',
  tittle: 'Soluções Através de Tecnologias Open Source',
  verticalArt: '/assets/test.svg',
  rootClassName: '',
}

BlockRight.propTypes = {
  verticalAlt: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  tittle: PropTypes.string,
  verticalArt: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default BlockRight

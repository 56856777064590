import React from 'react'

import PropTypes from 'prop-types'

import './about-huge.css'

const AboutHuge = (props) => {
  return (
    <div className={`about-huge-container ${props.rootClassName} `}>
      <img
        alt={props.imageAlt}
        src={props.imageSrc}
        className="about-huge-image"
      />
      <span className="about-huge-text titlecard">{props.text}</span>
      <span className="about-huge-text1 Content">{props.text1}</span>
    </div>
  )
}

AboutHuge.defaultProps = {
  rootClassName: '',
  imageAlt: 'image',
  text: 'Colaboração Aberta',
  text1:
    'Integração com diferentes agentes do ecossistema de inovação, como renomadas universidades, institutos de pesquisa, clientes e fornecedores, a fim de criar soluções colaborativas.',
  imageSrc: 'https://play.teleporthq.io/static/svg/placeholders/no-image.svg',
}

AboutHuge.propTypes = {
  rootClassName: PropTypes.string,
  imageAlt: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  imageSrc: PropTypes.string,
}

export default AboutHuge

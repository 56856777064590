import React from 'react'

import PropTypes from 'prop-types'

import './branding.css'

const Branding = (props) => {
  return (
    <div className={`branding-branding ${props.rootClassName} `}>
      <img
        alt={props.imageAlt}
        src={props.imageSrc}
        className="branding-image"
      />
    </div>
  )
}

Branding.defaultProps = {
  logoAlt: 'image',
  rootClassName: '',
  companyName: 'Helpa',
  imageSrc: 'https://play.teleporthq.io/static/svg/placeholders/no-image.svg',
  logoSrc: 'https://play.teleporthq.io/static/svg/placeholders/no-image.svg',
  imageAlt: 'image',
}

Branding.propTypes = {
  logoAlt: PropTypes.string,
  rootClassName: PropTypes.string,
  companyName: PropTypes.string,
  imageSrc: PropTypes.string,
  logoSrc: PropTypes.string,
  imageAlt: PropTypes.string,
}

export default Branding
